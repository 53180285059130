import { graphql, Link } from "gatsby"
import Carousel, { Modal, ModalGateway } from "react-images"
import get from "lodash/get"
import map from "lodash/map"
import React, { useState } from "react"

import Gallery from "./gallery"
import Image from "./gallery/image"
import Layout from "./layout"
import SEO from "./seo"

const Template = ({ data }) => {
  const { markdownRemark: post } = data

  const images = map(data.allFile.edges, ({ node }) => ({
    source: get(node, "childImageSharp.fluid.src"),
  }))

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />

      <div>
        <h1>{post.frontmatter.title}</h1>

        <Gallery images={images} />

        <br />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <p>
          <Link to="/folio">Back to Folio</Link>
        </p>
      </div>
    </Layout>
  )
}

export default Template

export const query = graphql`
  query AlbumQuery($path: String!, $folder: String!) {
    allFile(
      filter: {
        relativeDirectory: { eq: $folder }
        sourceInstanceName: { eq: "folios" }
        extension: { eq: "jpg" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
